<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">User</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>

        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="primary" @click="$router.back()">Back</base-button>
          <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Edit User</h3>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="4">
                    <base-input label="Username" placeholder="Your name" v-model="user.name"></base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input label="Email" placeholder="Email" v-model="user.email" readonly></base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input label="Role">
                      <el-select v-model="selects.simple" filterable
                                  placeholder="Select Role">
                        <el-option v-for="option in selectOptions"
                                    :key="option.role_id"
                                    :label="option.role_name"
                                    :value="option.role_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <base-input label="Password" placeholder="Password" :type="password_status" v-model="user.password" @mousedown="password_status = 'text'" @mouseup="password_status = 'password'"></base-input>
                  </b-col>
                  <b-col lg="6">
                    <base-input label="Password Confirmation"
                      name="password_confirmation"
                      :type="password_status"
                      placeholder="Password Confirmation"
                      v-model="user.password_confirmation" @mousedown="password_status = 'text'" @mouseup="password_status = 'password'">
                    </base-input>
                  </b-col>
                </b-row>
                <b-form-checkbox size="lg" class="mb-3" @change="triggerSelectSubsidiary()" v-model="checkboxes.hod">Is this user a HOD (check for yes)</b-form-checkbox>
                <b-row>
                  <b-col md="6">
                    <base-input label="Subsidiary List">
                      <el-select v-model="model.subsidiary" @change="changeHODStatus()" multiple filterable placeholder="Select Subsidiary">
                        <el-option Selected disabled value="x">-- Please Select Subsidiary for this HOD --</el-option>
                        <el-option v-for="option in selectSubsidiaryOptions" :key="option.sub_name" :label="option.sub_name" v-bind:value="option.sub_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>

                <base-button type="primary" @click="updateData()">Save</base-button>
                <base-button type="primary" @click="$router.back()">Cancel</base-button>
              </form>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import { Select , Option } from 'element-ui'
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    created(){
        this.roleList()
        axios.get(apiDomain + 'setup/user/' + this.$route.params.id ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.user.name      = response.data.data[0].name
            this.user.email     = response.data.data[0].email
            if(response.data.data[0].is_hod == 1)
            {
              this.checkboxes.hod = true;
            }
            else
            {
              this.checkboxes.hod = false;
            }
            this.selects.simple = response.data.data[0].role.role_id

            const obj = response.data.data[0].hod_of_sub
            let list = [];
            Object.keys(obj).forEach(key =>{
              list.push(obj[key].sub_id)
            })
            this.model.subsidiary = list

            const str = JSON.stringify(response.data.data[0], null, 2);
            console.log(str);
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show User")
            
        });

        axios.get(apiDomain + 'setup/subsidiary' ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {        
            this.selectSubsidiaryOptions = response.data.data;    
            // const str = JSON.stringify(response.data.data, null, 2);
            // console.log(str);
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show Subsidiary")
            
        });
    },
    data() {
      return {
        user: {
          name: null,
          email: null,
          password: null,
        },
        model: {
          subsidiary:[],
        },
        selects:{
          simple:[]
        },
        checkboxes:{
          hod:false
        },
        password_status: null,
        selectOptions: [],
        selectSubsidiaryOptions: []
      }
    },
    methods: {
      triggerSelectSubsidiary () {
        if(!this.checkboxes.hod && this.model.subsidiary.length > 0)
        {
          this.model.subsidiary = [];
        }
      },
      changeHODStatus() {
        if(this.model.subsidiary.length == 0)
        {
          this.checkboxes.hod = false;
        }
        else
        {
          this.checkboxes.hod = true;
        }
      },
      updateData(){

        if(this.checkboxes.hod)
        {
          if(this.model.subsidiary.length == 0)
          {
            this.redErrorNotify( '<b>Invalid Input : Subsidiary is Mandatory </b>')
          }
          else
          {
            this.updateUser();
          }
        }
        else
        {
          this.updateUser();
        } 
      },
      updateUser(){
        const postData = {
          name : this.user.name,
          password : this.user.password,
          password_confirmation: this.user.password_confirmation,
          email : this.user.email,
          is_hod : this.checkboxes.hod,
          role_id : this.selects.simple,
          active_status : 1
        }

        axios.put(apiDomain + 'setup/user/' + this.$route.params.id , postData ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.$notify({
              message:
                '<b>New User : ' + response.data.data[0].name + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            this.$router.back()
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Add User")
            
        });

        const postData1 = {
          sub_id : this.model.subsidiary,
          user_id : this.$route.params.id,
        }
    
        axios.post(apiDomain + 'setup/head_of_department', postData1 ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.$notify({
              message:
                '<b>User : ' + this.user.name + '</b> - Successfully Updated HOD Status.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            // this.$router.back()

          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Update HOD")
            
        });
      },
      roleList(){
        axios.get(apiDomain + 'setup/role', { headers: getHeader() })
        .then(response => {
          this.selectOptions = response.data.data
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Show Role")
        });
      console.log(this.info)   
      },
    }
  }
</script>
