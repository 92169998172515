<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Subsidiary</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>

        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="primary" @click="$router.back()">Back</base-button>
          <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">New Subsidiary</h3>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="6">
                    <base-input label="Subsidiary Name" placeholder="Subsidiary Name" v-model="sub.sub_name" ></base-input>
                  </b-col> 
                  
                  <b-col md="6">
                    <base-input label="HOD List">
                      <el-select v-model="model.hod" multiple filterable placeholder="Select HOD(s)">
                        <el-option Selected disabled value="x">-- Please Select User(s) as HOD --</el-option>
                        <el-option v-for="option in selectOptions" :key="option.name" :label="option.name" v-bind:value="option.id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>
                <base-button type="primary" @click="createData()">Create</base-button>
                <base-button type="primary" @click="$router.back()">Cancel</base-button>
              </form>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import { Select , Option } from 'element-ui'
  import axios from 'axios'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components:{
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        sub: {
          sub_name: null,
        },
        model: {
          hod: null
        },
        selectOptions: [],
      }
    },
    created() {
      this.userList()
    },
    methods: {
      createData(){
        if(!this.sub.sub_name){
            this.redErrorNotify( '<b>Invalid Input : Subsidiary Name is Mandatory </b>')
        }else if(this.model.hod.length == 0){
            this.redErrorNotify( '<b>Invalid Input : Head of Department is Mandatory </b>')
        }else{
          const postData = {
            sub_name : this.sub.sub_name,
            user_id  : this.model.hod,
          }
          const str = JSON.stringify(postData);
          console.log(str);
          console.table(postData);

          axios.post(apiDomain + 'setup/subsidiary', postData ,{ headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.$notify({
                message:
                  '<b>New User : ' + response.data.data[0].name + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
              this.$router.back()

            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Add User")
              
          });
        }
      },
      userList(){
        axios.get(apiDomain + 'setup/user', { headers: getHeader() })
        .then(response => {
          this.selectOptions = response.data.data
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Add User")
        });
      }
    }
  }
</script>
