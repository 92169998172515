<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Document</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>

        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="primary" @click="$router.back()">Back</base-button>
          <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">New Document</h3>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="4">
                    <base-input label="Document Title" placeholder="Document Title" v-model="doc.title"></base-input>
                  </b-col>
                  
                  <b-col md="4">
                    <base-input label="Application" >
                      <el-select v-model="selects.app" filterable placeholder="Select Apps" v-on:change="docTypeList(selects.app)">
                        <el-option Selected disabled value="x">-- Please Select Application --</el-option>
                        <el-option v-for="option in selectAppOptions" :key="option.app_type_name" :label="option.app_type_name" v-bind:value="option.app_type_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>

                  <b-col md="4">
                    <base-input label="Document Type">
                      <el-select v-model="selects.doc" filterable placeholder="Select Docs">
                        <el-option Selected disabled value="x">-- Please Select Document Type --</el-option>
                        <el-option v-for="option in selectDocOptions" :key="option.doc_type_name" :label="option.doc_type_name" v-bind:value="option.doc_type_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>

                </b-row>


                <base-button type="primary" @click="createData()">Create</base-button>
                <base-button type="primary" @click="$router.back()">Cancel</base-button>

              </form>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import { Select , Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components:{
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        doc: {
          title: null,
        },
        selects:{
          app:[],
          doc:[]
        },
        selectAppOptions:[],
        selectDocOptions:[]


      }
    },
    created() {
      this.appTypeList()
    },
    methods: {
      createData(){
        const postData = {
          doc_title : this.doc.title,
          doc_type_id  : this.selects.doc,
          app_type_id  : this.selects.app
        }
        
        const str = JSON.stringify(postData);
        console.log(str);
        console.table(postData);

        axios.post(apiDomain + 'document/document' , postData ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.$notify({
              message:
                '<b>Add Document : ' + this.doc.title + '</b> - Successfully Saved.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            this.$router.push({path: '/doc/editDocuments/' + response.data.data[0].doc_id })
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Add Document")
            
        });
      },
      appTypeList(){
        axios.get(apiDomain + 'setup/app_type.show_available_app_type_for_create_byUserID', { headers: getHeader() })
        .then(response => {

            const obj = response.data.data

            Object.keys(obj).forEach(key =>{
              obj[key].app_type_name = obj[key].app_type_name + " ( " + obj[key].sub.sub_name + " )"
            })
          this.selectAppOptions = obj
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Add Document")
            
        });
      console.log(this.info)   
      },
      docTypeList(app_type_id){

        axios.get(apiDomain + 'setup/document_type.show_available_document_type_for_create_byUserIDandAppTypeID/' + app_type_id, { headers: getHeader() })
        .then(response => {
          this.selectDocOptions = response.data.data
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Add Document")
            
        });
      console.log(this.info)   
      }
    },
    // beforeDestroy(){
    //   const str = JSON.stringify(this.selectAppOptions, null, 2);
    //   console.log(str);
    //   // console.table(this.selectAppOptions)
    // }
  }
</script>
<style>
@media only screen and (max-width: 450px) {  
  .el-select-dropdown{
    width: 300px !important;
    right: 10 !important;
  }
}

</style>
