<template>
  <b-card no-body>
    <b-card-header class="border-0">
      <b-row >
        <b-col cols="6">
          <h3 class="mb-0">Subsidiaries</h3>
        </b-col>
        <b-col cols="6" class="text-right">
          <!-- <el-tooltip content="Add a new Subsidiary" placement="top"> -->
            <base-button type="primary" icon size="sm" @click="addSub()">
              <!-- <span class="btn-inner--icon"><i class="fas fa-edit"></i></span> -->
              <span class="btn-inner--text">Add</span>
            </base-button>

          <!-- </el-tooltip> -->
        </b-col>
      </b-row>
    </b-card-header>
    <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between">
      <el-select
        class="select-primary pagination-select p-2"
        v-model="pagination.perPage"
        placeholder="Per page"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <div class="filler"></div>
      <div class="p-2 same-width">
        <base-input v-model="searchText"
                    prepend-icon="fas fa-search"
                    placeholder="Search...">
        </base-input>
      </div>
      <el-select class="select-primary pagination-select p-2 same-width" v-model="selects.simple" filterable placeholder="Select Column">
        <!-- <el-option Selected disabled value="x">-- Please Select Column --</el-option> -->
        <el-option v-for="option in selectOptions" :key="option.key" :label="option.value" v-bind:value="option.key">
        </el-option>
      </el-select>

      <div class="p-2">
        <base-button icon type="primary" size="sm" @click.prevent="searchSubList()">Search</base-button>
      </div>
    </b-col>
    <el-table class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="queriedData"
              @sort-change="sortChange"
              @selection-change="selectionChange"
              row-key="id">
      <el-table-column label="Id"
                       min-width="100px"
                       prop="id"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.sub_id}}</b>
        </template>
      </el-table-column>
      <el-table-column label="Subsidiary name"
                       min-width="210px"
                       prop="sub_name"
                       sortable>
        <template v-slot="{row}">
          <!--<b-img :src="row.image" rounded="circle" class="avatar mr-3" />-->
          <b>{{row.sub_name}}</b>
        </template>
      </el-table-column>

      <el-table-column label="HOD List"
                       prop="hod"
                       min-width="240px"
                       sortable>
        <template v-slot="{row}">
          <li v-for="item in row.hod" :key="item.hod_id"> {{item.user.name}} <br></li>
        </template>
      </el-table-column>

      <el-table-column label="Created By"
                       min-width="200px"
                       prop="created_by"
                       sortable>
        <template v-slot="{row}">
          <a href="#!" class="font-weight-bold">{{row.created_by.name}}</a>
        </template>
      </el-table-column>

      <el-table-column min-width="180px" align="center">
        <div slot-scope="{row}" class="table-actions">
          <!-- <el-tooltip content="Edit" placement="top">
            <a href="#!" @click.prevent="onEdit(row)" class="table-action" data-toggle="tooltip" data-original-title="Edit product">
              <i class="fas fa-edit"></i>
            </a>
          </el-tooltip>
          <el-tooltip content="Delete" placement="top">
            <a href="#!" @click.prevent="onDelete(row)" class="table-action table-action-delete" data-toggle="tooltip"
               data-original-title="Delete product">
              <i class="fas fa-trash"></i>
            </a>
          </el-tooltip> -->
          <base-button type="primary" icon size="sm" @click.prevent="onEdit(row)">
            <span class="btn-inner--text">Edit</span>
            </base-button>
          <base-button type="primary" icon size="sm" @click.prevent="onDelete(row)">
            <span class="btn-inner--text">Delete</span>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

          <span v-if="selectedRows.length">
            &nbsp; &nbsp; {{selectedRows.length}} rows selected
          </span>
        </p>

      </div>
      <base-pagination
        class="pagination-no-border"
        :current="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
        @change="paginationChanged($event)"
      >
      </base-pagination>
    </div>
  </b-card>

</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import { BasePagination } from '@/components';
  import clientPaginationMixin from '@/assets/js/clientPaginationMixin'
  import debounce from 'lodash.debounce';

  export default {
    name: 'subsidiaries-table',
    mixins: [ErrorFunctionMixin,clientPaginationMixin],
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        propsToSearch:['sub_name'],
        tableData: [],
        currentPage: 1,
        mode: 'listing',
        selectedRows: [],
        searchText: '',
        TableColumn:[        {
          type: 'selection'
        }],
        selects: {
          simple: 'all',
        },
        selectOptions: [
          {
            key:'all',
            value:'All'
          },
          {
            key :'sub_name',
            value: 'Subsidiary Name'
          },
          {
            key :'created_by',
            value: 'Created By'
          }
        ],
      };
    },
    created () {
      this.subList(),
      this.debouncedHandler = debounce(() => {
        this.searchSubList();
      }, 500);
    },
    methods: {
      paginationChanged(page) {
        this.pagination.currentPage = page
      },
      selectionChange(selectedRows) {
      this.selectedRows = selectedRows
      },
      onEdit(row) {
        this.$router.push({path: '/setup/editSubsidiaries/' + row.sub_id })
        // alert(`You want to edit ${row.name}`)
      },
      onDelete(row) {
        swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          // denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.delete(apiDomain + 'setup/subsidiary/' + row.sub_id,{ headers: getHeader() })
            .then ( response => {
              if (response.status === 200)
              {
                this.$notify({
                  message:
                    '<b>Subsidiary : ' + row.sub_name + '</b> - Successfully Deleted.',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
                this.subList()
              }
            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Delete Subsidiary")
                
            });
          } 
        })
      },
      addSub(){
        this.$router.push({path: '/setup/addSubsidiaries' })
      },
      subList(){
        axios.get(apiDomain + 'setup/subsidiary', { headers: getHeader() })
        .then(response => {
          this.tableData = response.data.data
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Delete Subsidiary")
        });

      console.log(this.info)   
      },
      searchSubList()
      {
        if(this.selects.simple == "all"){
          this.$notify({
            message:
              '<b>Subsidiary : </b> - Search Successful.',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          return this.subList()
        }

        const postData = {
          search_by  : this.selects.simple,
          search_value : this.searchText,
        }

        axios.post(apiDomain + 'setup/data_search.subsidiary', postData  ,{ headers: getHeader() })
        .then ( response => {
          if(response.data.data.length === 0){
            return this.$notify({
              message:
                '<b>Subsidiary : </b> - No Results.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          }
          if (response.status === 200)
          {
            this.tableData = response.data.data
            // console.log(this.tableData)
            this.searchText = null

            this.$notify({
              message:
                '<b>Subsidiary : </b> - Search Successful.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });

          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Subsidiary Listing")
        });
      }

    }
  }
</script>
<style scoped>
.filler{flex-grow:1; text-align:center}
@media only screen and (max-width: 600px) {
  .same-width {
    width: 100%;
  }
}
</style>