<template>
  <b-card no-body>
    <b-card-header class="border-0">
      <b-row >
        <b-col cols="6">
          <h3 class="mb-0">Document</h3>
        </b-col>
        <b-col cols="6" class="text-right">
          <!-- <el-tooltip content="Add a new Document" placement="top"> -->
            <base-button type="primary" icon size="sm" @click="addDoc()">
              <!-- <span class="btn-inner--icon"><i class="fas fa-edit"></i></span> -->
              <span class="btn-inner--text">Add</span>
            </base-button>

          <!-- </el-tooltip> -->
        </b-col>
      </b-row>
    </b-card-header>
    <!-- <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between" align-v="center">
      <el-select
        class="select-primary pagination-select p-2"
        v-model="pagination.perPage"
        placeholder="Per page"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <div class="p-2">
        <base-input v-model="searchQuery"
                    prepend-icon="fas fa-search"
                    placeholder="Search...(case-sensitive)">
        </base-input>
      </div>
    </b-col> -->
    <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap" align-v="center">
      <el-select
        class="select-primary pagination-select p-2 same-width"
        v-model="pagination.perPage"
        placeholder="Per page"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <!-- <div class="filler"></div> -->

      <!-- Filter by Application -->
      <div class="p-2 same-width">
        <el-select class="select-primary pagination-select same-width" v-model="selectedFilterByApp" filterable placeholder="Filter By Application" @change="filterByApp">
          <el-option v-for="option in optionsFilterByApp" :key="option.app_type_id" :label="option.app_type_name" v-bind:value="option.app_type_id" >
          </el-option>
        </el-select>
      </div>

      <!-- Filter by Document Type -->
      <div class="p-2 same-width">
        <el-select class="select-primary pagination-select same-width" v-model="selectedFilterByDocType" filterable placeholder="Filter By Document Type"  @change="filterByDocType">
          <el-option v-for="option in optionsFilterByDocType" :key="option.doc_type_id" :label="option.doc_type_name" v-bind:value="option.doc_type_id">
          </el-option>
        </el-select>
      </div>

      <!-- Search -->
      <div class="p-2 same-width">
        <base-input v-model="search"
                    prepend-icon="fas fa-search"
                    placeholder="Search...">
        </base-input>
      </div>
      <el-select class="select-primary pagination-select p-2 same-width" v-model="selects.simple" filterable placeholder="Select Column">
        <!-- <el-option Selected disabled value="x">-- Please Select Column --</el-option> -->
        <el-option v-for="option in selectOptions" :key="option.key" :label="option.value" v-bind:value="option.key">
        </el-option>
      </el-select>

      <div class="p-2">
        <base-button icon type="primary" size="sm" @click.prevent="searchData()">Search</base-button>
      </div>

    </b-col>
    <el-table class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="queriedData"
              @sort-change="sortChange"
              @selection-change="selectionChange"
              row-key="doc_id">
      <el-table-column label="id"
                       min-width="100px"
                       prop="doc_id"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.doc_id}}</b>
        </template>
      </el-table-column>

      <el-table-column label="Title and Group"
                       prop="doc_title"
                       min-width="170px"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.doc_title}}</b><br>
          <b>{{row.sub.sub_name}}</b>
        </template>
      </el-table-column>

      <!-- <el-table-column label="Subsidiary"
                       min-width="150px"
                       prop="sub_name"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.sub.sub_name}}</b>
        </template>
      </el-table-column> -->

      <el-table-column label="Application and Document Type"
                       min-width="200px"
                       prop="app_type_name"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.app_type.app_type_name}}</b><br>
          <b>{{row.document_type.doc_type_name}}</b>
        </template>
      </el-table-column>

      <!-- <el-table-column label="Doc Type"
                       min-width="150px"
                       prop="doc_type_name"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.document_type.doc_type_name}}</b>
        </template>
      </el-table-column> -->
      
       <el-table-column label="Last Updated and Created By"
                       min-width="180px"
                       prop="last_updated"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.updated_at}}</b><br>
          <b>{{row.created_by.name}}</b>
        </template>
      </el-table-column>

      <!-- <el-table-column label="Created By and Last Updated"
                       min-width="180px"
                       prop="created_by"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.created_by.name}}</b><br>
          <b>{{row.updated_at}}</b><br>
        </template>
      </el-table-column> -->

      <el-table-column min-width="180px" align="center">
        <div slot-scope="{row}" class="table-actions">
          <!-- <el-tooltip content="View" placement="top">
            <a href="#!" @click.prevent="onView(row)" class="table-action" data-toggle="tooltip" data-original-title="Doc Edit">
              <i class="fas fa-book-open"></i>
            </a>
          </el-tooltip>
          <el-tooltip content="Edit" placement="top" v-if="row.app_setting.app_access_edit==1">
            <a href="#!" @click.prevent="onEdit(row)" class="table-action" data-toggle="tooltip" data-original-title="Doc Edit">
              <i class="fas fa-edit"></i>
            </a>
          </el-tooltip> -->
          <base-button v-if="row.app_setting.app_access_show==1" type="primary" icon size="sm" @click.prevent="onView(row)">
            <span class="btn-inner--text">View</span>
          </base-button>
          <base-button v-if="row.app_setting.app_access_edit==1" type="primary" icon size="sm" @click.prevent="onEdit(row)">
            <span class="btn-inner--text">Edit</span>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

          <span v-if="selectedRows.length">
            &nbsp; &nbsp; {{selectedRows.length}} rows selected
          </span>
        </p>

      </div>
      <base-pagination
        class="pagination-no-border"
        :current="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
        @change="paginationChanged($event)"
      >
      </base-pagination>
    </div>
  </b-card>

</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import { BasePagination } from '@/components';
  import clientPaginationMixin from '@/assets/js/clientPaginationMixin'

  export default {
    name: 'documents-table',
    mixins: [ErrorFunctionMixin,clientPaginationMixin],
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        propsToSearch:['doc_title'],
        tableData: [],
        currentPage: 1,
        mode: 'listing',
        selectedRows: [],
        TableColumn:[{
          type: 'selection'
        }],
        selects: {
          simple: 'all',
        },
        selectOptions: [
          {
            key:'all',
            value:'All'
          },
          {
            key :'doc_title',
            value: 'Title'
          },
          {
            key :'app_type_name',
            value: 'Application Type'
          },
          {
            key :'doc_type_name',
            value: 'Document Type'
          },
          {
            key :'created_by',
            value: 'Created By'
          },
          {
            key :'last_updated',
            value: 'Last Updated'
          }
        ],
        search:null,
        selectedFilterByApp: null,
        optionsFilterByApp: [],
        selectedFilterByDocType: null,
        optionsFilterByDocType: [],
      };
    },
    mounted(){
      this.docList()
          const str = JSON.stringify(this.selectOptions,null,2);
      
    },
    methods: {
      paginationChanged(page) {
        this.pagination.currentPage = page
      },
      selectionChange(selectedRows) {
      this.selectedRows = selectedRows
      },
      onView(row) {
        this.$router.push({path: '/doc/viewDocuments/' + row.doc_id })
      },
      onEdit(row) {
        this.$router.push({path: '/doc/editDocuments/' + row.doc_id })
      },
      onDelete(row) {
        alert(`You want to delete ${row.name}`)
      },
      addDoc(){
        this.$router.push({path: '/doc/addDocuments' })
      },
      docList(){
        axios.get(apiDomain + 'document/document', { headers: getHeader() })
        .then(response => {
          this.tableData = response.data.data;
            
          // const obj = response.data.data[0]
          // let list = [];
          // Object.keys(obj).forEach(key =>{
          //   list.push(key)
          // })
          // this.selectOptions = list;
          // const str = JSON.stringify(list,null,2);

          let selectListFilterByApp = [];
          Object.values(this.tableData).forEach(values => {
            selectListFilterByApp.push(values.app_type);
          })
          this.optionsFilterByApp = selectListFilterByApp;

          this.optionsFilterByApp = [...new Map(selectListFilterByApp.map(item =>[item['app_type_id'], item])).values()];

          let selectListFilterByDocType = [];
          Object.values(this.tableData).forEach(values => {
            selectListFilterByDocType.push(values.document_type);
          })
          this.optionsFilterByDocType = selectListFilterByDocType;

          this.optionsFilterByDocType = [...new Map(selectListFilterByDocType.map(item =>[item['doc_type_id'], item])).values()];

          // console.log(selectListFilterByDocType);

          // console.log(this.tableData);
        })
        .catch ( error => {
            console.log(error)
            this.errorFunction(error, "Add Document")
            
        });

      },
      searchData(){
        if(this.selects.simple == "all"){
          this.$notify({
            message:
              '<b>Document : </b> - Search Successful.',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          return this.docList()
        }
        const postData = {
          search_by  : this.selects.simple,
          search_value : this.search,
        }

        axios.post(apiDomain + 'document/document.show_by_search', postData,{ headers: getHeader() })
        .then(response => {
          if(response.data.data.length === 0){
            return  this.$notify({
                      message:
                        '<b>Document : </b> - No Results.',
                      timeout: 10000,
                      icon: 'ni ni-bell-55',
                      type: 'default',
                    });
          }
          this.tableData = response.data.data
          this.search = null
              if (response.status === 200)
              {
                this.$notify({
                  message:
                    '<b>Document : </b> - Search Successful.',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
              }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Search Document")
        });

      },
      filterByApp(val){
        axios.get(apiDomain + 'document/document.filter_by_application/' + val, { headers: getHeader() })
        .then(response => {
          this.selectedFilterByDocType = null;
          this.tableData = response.data.data
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Filter App")
        });
      },
      filterByDocType(val){
        axios.get(apiDomain + 'document/document.filter_by_doc_type/' + val, { headers: getHeader() })
        .then(response => {
          this.selectedFilterByApp = null;
          this.tableData = response.data.data
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Filter Doc Type")
        });
      }
    }
  }
</script>
<style >
.filler{flex-grow:1; text-align:center}
@media only screen and (max-width: 600px) {
  .same-width {
    width: 100%;
  }
}

.el-table .cell {
  word-break: break-word !important;
}
</style>