var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"border-0"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h3',{staticClass:"mb-0"},[_vm._v("Group Application - ("+_vm._s(_vm.groupName)+")")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.addGroupAppSetting()}}},[_c('span',{staticClass:"btn-inner--text"},[_vm._v("Add")])])],1)],1)],1),_c('el-table',{staticClass:"table-responsive align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.tableData}},[_c('el-table-column',{attrs:{"label":"id","min-width":"70px","prop":"app_type_id","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.app_type.app_type_id))])]}}])}),_c('el-table-column',{attrs:{"label":"Name","prop":"app_type_name","min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.app_type.app_type_name))])]}}])}),_c('el-table-column',{attrs:{"label":"Permission","min-width":"200px","prop":"created_by","sortable":"","label-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"table-actions form-row"},[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"value":"1","unchecked-value":"0"},on:{"change":function($event){return _vm.onChange(row)}},model:{value:(row.app_access_create),callback:function ($$v) {_vm.$set(row, "app_access_create", $$v)},expression:"row.app_access_create"}},[_vm._v(" create ")])],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"value":"1","unchecked-value":"0"},on:{"change":function($event){return _vm.onChange(row)}},model:{value:(row.app_access_show),callback:function ($$v) {_vm.$set(row, "app_access_show", $$v)},expression:"row.app_access_show"}},[_vm._v(" show ")])],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"value":"1","unchecked-value":"0"},on:{"change":function($event){return _vm.onChange(row)}},model:{value:(row.app_access_edit),callback:function ($$v) {_vm.$set(row, "app_access_edit", $$v)},expression:"row.app_access_edit"}},[_vm._v(" edit ")])],1)],1)}}])}),_c('el-table-column',{attrs:{"label":"Created By","min-width":"150px","prop":"created_by","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.created_by.name))])]}}])}),_c('el-table-column',{attrs:{"min-width":"50px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.onDelete(row)}}},[_c('span',{staticClass:"btn-inner--text"},[_vm._v("Delete")])])],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }