<template>
  <b-card no-body>
    <b-card-header class="border-0">
      <b-row >
        <b-col cols="6">
          <h3 class="mb-0">Group Document Type - ({{groupName}})</h3>
        </b-col>
        <b-col cols="6" class="text-right">
          <!-- <el-tooltip content="Add a new Group" placement="top"> -->
            <base-button type="primary" icon size="sm" @click="addGroupDocSetting()">
              <!-- <span class="btn-inner--icon"><i class="fas fa-edit"></i></span> -->
              <span class="btn-inner--text">Add</span>
            </base-button>

          <!-- </el-tooltip> -->
        </b-col>
      </b-row>
    </b-card-header>

    <el-table class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="tableData">
      <el-table-column label="id"
                       min-width="100px"
                       prop="doc_type_id"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.doc_type.doc_type_id}}</b>
        </template>
      </el-table-column>

      <el-table-column label="Name"
                       prop="doc_type_name"
                       min-width="150px"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.doc_type.doc_type_name}}</b>
        </template>
      </el-table-column>



      <el-table-column label="Created By"
                       min-width="150px"
                       prop="created_by"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.created_by.name}}</b>
        </template>
      </el-table-column>

      <el-table-column min-width="180px" align="center">
        <div slot-scope="{row}" class="table-actions">
          <!-- <el-tooltip content="Delete" placement="top">
            <a href="#!" @click.prevent="onDelete(row)" class="table-action table-action-delete" data-toggle="tooltip"
               data-original-title="Delete product">
              <i class="fas fa-trash"></i>
            </a>
          </el-tooltip> -->
          <base-button type="primary" icon size="sm" @click.prevent="onDelete(row)">
            <span class="btn-inner--text">Delete</span>
          </base-button>
        </div>
      </el-table-column>
    </el-table>


  </b-card>

</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';

  export default {
    name: 'group-doc-setting-table',
    props : [ "groupName" ],
    mixins: [ErrorFunctionMixin],
    components: {
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        tableData: null,
        currentPage: 1,
        mode: 'listing',
      };
    },
    mounted(){
      this.groupDocList()
    },
    methods: {
      addGroupDocSetting(){
        this.$router.push({path: '/group/addGroupDocSetting/' + this.$route.params.id })
      },
      onDelete(row) {
        swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          // denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.delete(apiDomain + 'groups/group_document_setting/' + row.group_doc_setting_id,{ headers: getHeader() })
            .then ( response => {
              if (response.status === 200)
              { 
                this.$notify({
                  message:
                    '<b>Group Doc Type : ' + row.doc_type.doc_type_name + '</b> - Successfully Deleted.',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
                this.groupDocList()
              }
            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Delete App Type")
                
            });
          } 
        })
      },
      groupDocList(){
        axios.get(apiDomain + 'groups/group_document_setting.show_by_group/' + this.$route.params.id, { headers: getHeader() })
        .then(response => {
          this.tableData = response.data.data
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Delete App Type")
            
        });
        const str = JSON.stringify(this.tableData, null, 2);
        console.log(str);
      }

    }
  }
</script>
