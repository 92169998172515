var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"border-0"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h3',{staticClass:"mb-0"},[_vm._v("Group Members - ("+_vm._s(_vm.groupName)+")")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.addGroupMembers()}}},[_c('span',{staticClass:"btn-inner--text"},[_vm._v("Add")])])],1)],1)],1),_c('b-col',{staticClass:"d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"cols":"12"}},[_c('el-select',{staticClass:"select-primary pagination-select form-group",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1)],1),_c('el-table',{staticClass:"table-responsive align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.queriedData,"row-key":"group_member_id"},on:{"sort-change":_vm.sortChange,"selection-change":_vm.selectionChange}},[_c('el-table-column',{attrs:{"label":"id","min-width":"100px","prop":"group_member_id","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.group_member_id))])]}}])}),_c('el-table-column',{attrs:{"label":"Name","prop":"name","min-width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.user.name))])]}}])}),_c('el-table-column',{attrs:{"label":"Email","prop":"emil","min-width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.user.email))])]}}])}),_c('el-table-column',{attrs:{"label":"Created By","min-width":"150px","prop":"created_by","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.created_by.name))])]}}])}),_c('el-table-column',{attrs:{"min-width":"180px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.onDelete(row)}}},[_c('span',{staticClass:"btn-inner--text"},[_vm._v("Delete")])])],1)}}])})],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Showing "+_vm._s(_vm.from + 1)+" to "+_vm._s(_vm.to)+" of "+_vm._s(_vm.total)+" entries "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.selectedRows.length)+" rows selected ")]):_vm._e()])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"current":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"total":_vm.total},on:{"change":function($event){return _vm.paginationChanged($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }