<template>
  <b-card no-body>
    <b-card-header class="border-0">
      <b-row >
        <b-col cols="6">
          <h3 class="mb-0">Group Docs  - ({{groupName}})</h3>
        </b-col>
        <!-- <b-col cols="6" class="text-right">
          <el-tooltip content="Add a new Group" placement="top">
            <base-button type="primary" icon size="sm" @click="addGroup()">
              <span class="btn-inner--icon"><i class="fas fa-edit"></i></span>
              <span class="btn-inner--text">Add</span>
            </base-button>

          </el-tooltip>
        </b-col> -->
      </b-row>
    </b-card-header>
    <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between">
      <el-select
        class="select-primary pagination-select p-2"
        v-model="pagination.perPage"
        placeholder="Per page"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <div class="filler"></div>
      <div class="p-2 same-width">
        <base-input v-model="searchText"
                    prepend-icon="fas fa-search"
                    placeholder="Search...">
        </base-input>
      </div>
      <el-select class="select-primary pagination-select p-2 same-width" v-model="selects.simple" filterable placeholder="Select Column">
        <!-- <el-option Selected disabled value="x">-- Please Select Column --</el-option> -->
        <el-option v-for="option in selectOptions" :key="option.key" :label="option.value" v-bind:value="option.key">
        </el-option>
      </el-select>

      <div class="p-2">
        <base-button icon type="primary" size="sm" @click.prevent="searchGroupDocList()">Search</base-button>
      </div>
    </b-col>
    <el-table class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="queriedData"
              @sort-change="sortChange"
              @selection-change="selectionChange"
              row-key="doc_id">
      <el-table-column label="id"
                       min-width="100px"
                       prop="doc_id"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.doc_id}}</b>
        </template>
      </el-table-column>

      <el-table-column label="Title"
                       prop="doc_title"
                       min-width="150px"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.doc_title}}</b>
        </template>
      </el-table-column>

      <el-table-column label="App Type"
                       min-width="150px"
                       prop="app_type_name"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.app_type.app_type_name}}</b>
        </template>
      </el-table-column>

      <el-table-column label="Doc Type"
                       min-width="150px"
                       prop="doc_type_name"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.document_type.doc_type_name}}</b>
        </template>
      </el-table-column>

      <el-table-column label="Created By"
                       min-width="150px"
                       prop="created_by"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.created_by.name}}</b>
        </template>
      </el-table-column>

      <!-- <el-table-column min-width="180px" align="center">
        <div slot-scope="{row}" class="table-actions">
          <el-tooltip content="Delete" placement="top">
            <a href="#!" @click.prevent="onDelete(row)" class="table-action table-action-delete" data-toggle="tooltip"
               data-original-title="Delete product">
              <i class="fas fa-trash"></i>
            </a>
          </el-tooltip>
        </div>
      </el-table-column> -->
    </el-table>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

          <span v-if="selectedRows.length">
            &nbsp; &nbsp; {{selectedRows.length}} rows selected
          </span>
        </p>

      </div>
      <base-pagination
        class="pagination-no-border"
        :current="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
        @change="paginationChanged($event)"
      >
      </base-pagination>
    </div>

  </b-card>

</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import { BasePagination } from '@/components';
  import clientPaginationMixin from '@/assets/js/clientPaginationMixin'
  import debounce from 'lodash.debounce';

  export default {
    name: 'group-doc-table',
    props : [ "groupName" ],
    mixins: [ErrorFunctionMixin,clientPaginationMixin],
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        propsToSearch:['doc_title'],
        tableData: [],
        currentPage: 1,
        mode: 'listing',
        selectedRows: [],
        searchText: null,
        debouncedHandler:null,
        TableColumn:[        {
          type: 'selection'
        }],
        selects: {
          simple: 'all',
        },
        selectOptions: [
          {
            key:'all',
            value:'All'
          },
          {
            key :'doc_title',
            value: 'Title'
          },
          {
            key :'app_type_name',
            value: 'Application Type'
          },
          {
            key :'doc_type_name',
            value: 'Document Type'
          },
          {
            key :'created_by',
            value: 'Created By'
          }
        ],
      };
    },
    mounted(){
      this.groupDocList()
    },
    methods: {
      paginationChanged(page) {
        this.pagination.currentPage = page
      },
      selectionChange(selectedRows) {
      this.selectedRows = selectedRows
      },
      groupDocList(){
        const postData = {
          group_id : this.$route.params.id,
          search_text : this.searchText,
        }
        axios.post(apiDomain + 'document/document.show_by_group', postData, { headers: getHeader() })
        .then(response => {
          this.tableData = response.data.data
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show Doc Group")
            
        });
                
        const str = JSON.stringify(this.tableData, null, 2);
        console.log(str);
      },
      searchGroupDocList()
      {
        if(this.selects.simple == "all"){
          this.$notify({
            message:
              '<b>DocGroups : </b> - Search Successful.',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          return this.groupDocList()
        }
        const postData = {
          group_id : this.$route.params.id,
          search_by  : this.selects.simple,
          search_value : this.searchText,
        }

        axios.post(apiDomain + 'document/document.show_by_group', postData  ,{ headers: getHeader() })
        .then ( response => {
          if(response.data.data.length === 0){
            return this.$notify({
              message:
                '<b>DocGroups : </b> - No Results.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          }
          if (response.status === 200)
          {
            this.tableData = response.data.data
            // console.log(this.tableData)
            this.$notify({
              message:
                '<b>DocGroups : </b> - Search Successful.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });

          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "DocGroups Listing")
        });
      }

    }
  }
</script>
<style scoped>
.filler{flex-grow:1; text-align:center}
@media only screen and (max-width: 600px) {
  .same-width {
    width: 100%;
  }
}
</style>
