<template>
  <card>
    <b-row align-v="center" slot="header" >
      <b-col cols="8">
        <h3 class="mb-0">Edit Profile </h3>
      </b-col>
    </b-row>

    <b-form @submit.prevent="updateProfile">
      <h6 class="heading-small text-muted mb-4">My Profile information</h6>

      <div class="pl-lg-4">
        <b-row>
          <b-col lg="6">
            <base-input
              type="text"
              label="Username"
              placeholder="Username"
              v-model="user.name"
            >
            </base-input>
          </b-col>
          <b-col lg="6">
            <base-input
              type="email"
              label="Email address"
              placeholder="mike@email.com"
              v-model="user.email"
              readonly
            >
            </base-input>
          </b-col>
        </b-row>
      </div>

      <div class="pl-lg-4">
        <b-row>
          <b-col lg="6">
            <base-input label="Password"
              name="password"
              type="password"
              placeholder="Password"
              v-model="user.password">
            </base-input>
          </b-col>
          <b-col lg="6">
            <base-input label="Password Confirmation"
              name="password_confirmation"
              type="password"
              placeholder="Password Confirmation"
              v-model="user.password_confirmation">
            </base-input>
          </b-col>
        </b-row>
      </div>

      <h5 class="text-danger"> Note : <br><br>
          <i>
            1. Password must be at least 8 characters in length.
          </i>
          <br>
          <i>
            2. Password must contain at least one lowercase letter. [a-z].
          </i>
          <br>
          <i>
            3. Password must contain at least one uppercase letter. [A-Z].
          </i>
          <br>
          <i>
            4. Password must contain at least one number. [0-9].
          </i>
          <br>
          <i>
            5. Password  must contain a special character from the list. [@$!%*#?&].
          </i>
          <br>
      </h5>
      
      <hr class="my-4">

      <base-button type="primary" @click="updateData()">Update / Save</base-button>


    </b-form>
  </card>
</template>
<script>

// import BaseSlider from '@/components/BaseSlider'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate, getUserInfo} from '@/assets/js/config.js'
import axios from 'axios'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default 
{
  name: 'edit-my-profile-form',
  mixins: [ErrorFunctionMixin],
  
  created()
  {
    this.getData();
    axios.get(apiDomain + 'api/my_profile/' + this.user.id ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.user.name      = response.data.data[0].name
            this.user.email     = response.data.data[0].email
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show User")
            
    });
  },
  data() {
    return {
      user: {        
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      }
    };
  },
  methods: {
    getData() 
    {
      this.user.id = getUserInfo().id;
      this.user.name = getUserInfo().name;
      this.user.email = getUserInfo().email;
    },
    updateProfile() 
    {
      alert('Your data: ' + JSON.stringify(this.user));
    },
    updateData(){
        const postData = {
          name : this.user.name,
          password : this.user.password,
          password_confirmation: this.user.password_confirmation
        }

        axios.put(apiDomain + 'api/my_profile/' + this.user.id , postData ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.$notify({
              message:
                '<b>Edit User : ' + response.data.data[0].name + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            this.user.password = '',
            this.user.password_confirmation = '';
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Add User")
            
        });

      },
  }
};
</script>
<style></style>
