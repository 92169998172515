<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Group</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="primary" @click="$router.back()">Back</base-button>
          <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
        </b-col>
       
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <group-member-table :group-name=group_name></group-member-table>
    </b-container>

  </div>
</template>

<script>
  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import GroupMemberTable from "@/views/Group/MemberGroupsTable";
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  
  export default {
    components: {
      GroupMemberTable,
      RouteBreadCrumb,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        group_name: null
      };
    },
    created(){
      axios.get(apiDomain + 'groups/group/' + this.$route.params.id, { headers: getHeader() })
      .then(response => {
        this.group_name = response.data.data[0].group_name
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Group Settings")
          
      });
    },
    methods: {
    }
  };
</script>
