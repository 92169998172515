<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Document</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>

        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="primary" @click="$router.back()">Back</base-button>
          <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>D
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0" v-if="doc_ori.have_draft == 0">Edit Document - {{doc.title}}</h3>
              <h3 slot="header" class="mb-0" v-else> Edit Document - {{doc.title}} ( Original : {{ doc_ori.original_title }} ) </h3>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="4">
                    <base-input label="Document Title" placeholder="Document Title" v-model="doc.title"></base-input>
                  </b-col>
                  
                  <b-col md="4">
                    <base-input label="Application">
                      <el-select v-model="selects.app" filterable placeholder="Select Apps" disabled>
                        <el-option Selected disabled value="x">-- Please Select Application --</el-option>
                        <el-option v-for="option in selectAppOptions" :key="option.app_type_name" :label="option.app_type_name" v-bind:value="option.app_type_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>

                  <b-col md="4">
                    <base-input label="Document Type">
                      <el-select v-model="selects.doc" filterable placeholder="Select Docs" disabled>
                        <el-option Selected disabled value="x">-- Please Select Document Type --</el-option>
                        <el-option v-for="option in selectDocOptions" :key="option.doc_type_name" :label="option.doc_type_name" v-bind:value="option.doc_type_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="10" >
                    <p v-if="last_updated_on != null">
                      <b>Draft Auto Saved On : {{ last_updated_on }}</b> (Document will auto saved as draft every 1 minute.)</p>
                  </b-col>
                  <b-col md="2" v-if="doc_ori.have_draft == 1" class="text-right form-group">
                    <b-button size="sm" v-b-toggle.collapse-1 variant="primary">Original Document Info</b-button>
                  </b-col>
                </b-row>
                
                <b-row>
                   <b-col md="12">
                    <b-collapse id="collapse-1">
                      <b-card title="Original Document Content">
                        <div v-html="original_doc_desc"></div>
                      </b-card>
                    </b-collapse>
                   </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <vue-editor @text-change="debouncedHandler" v-model="vue_editor_content"/>
                  </b-col>
                </b-row>
                <br>

                <b-row align-h="between" class="mb-4">
                  <b-col md="6">
                    <b-row>
                      <b-col>
                        <!-- Hash Tag -->
                        <el-select
                          v-model="tagValues"
                          multiple
                          filterable
                          allow-create
                          default-first-option
                          placeholder="Choose tags for your article">
                          <el-option
                            v-for="item in tagOptions"
                            :key="item.tag_id"
                            :label="item.tag_name"
                            :value="item.tag_name">
                          </el-option>
                        </el-select>
                      </b-col>
                      <b-col>
                        <!-- Tag Users -->
                        <!-- <el-select v-model="value1" multiple placeholder="Select">
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select> -->
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="5"></b-col>
                </b-row>

                <b-row>
                  <b-col md="6" class="form-group">
                    <vue-dropzone ref="myVueDropzone" id="dropzone" v-on:vdropzone-success="uploadSuccess" :options="dropzoneOptions"></vue-dropzone>
                    <!-- <br>
                    <base-button type="primary" @click="clearDropzone()">Clear upload</base-button> -->
                  </b-col>

                  <b-col md="6" class="form-group">
                    <el-table class="table-responsive align-items-center table-flush"
                              header-row-class-name="thead-light"
                              :data="tableData">
                      <el-table-column label="id"
                                      min-width="50px"
                                      prop="doc_attach_id"
                                      sortable>
                        <template v-slot="{row}">
                          <b>{{row.doc_attach_id}}</b>
                        </template>
                      </el-table-column>

                      <el-table-column label="attach file"
                                      prop="attach_file"
                                      min-width="150px"
                                      sortable>
                        <template v-slot="{row}">
                          <b>{{row.attach_file}}</b>
                        </template>
                      </el-table-column>

                      <el-table-column min-width="50px" align="center">
                        <div slot-scope="{row}" class="table-actions">
                          <el-tooltip content="Download" placement="top">
                            <a href="" @click.prevent="onDownload(row)" class="table-action" data-toggle="tooltip" data-original-title="Doc Edit">
                              <i class="fas fa-download"></i>
                            </a>
                          </el-tooltip>
                          <el-tooltip content="Delete" placement="top" v-if ="loginUser.role_id == 1 || loginUser.role_id == 2">
                            <a href="#!" @click.prevent="onDelete(row)" class="table-action" data-toggle="tooltip" data-original-title="Doc Edit">
                              <i class="fas fa-trash"></i>
                            </a>
                          </el-tooltip>
                        </div>
                      </el-table-column>
                    </el-table>
                  </b-col>
                </b-row>

                <base-button type="primary" @click="updateData()">Save</base-button>
                <base-button type="primary" @click="$router.back()">Cancel</base-button>
              </form>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import { Select , Option , Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip } from 'element-ui'
  import {apiDomain, getHeader, getcurrentDateTime, getUserInfo } from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import { VueEditor } from "vue2-editor";
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import debounce from "lodash.debounce";

  export default {
    mixins: [ErrorFunctionMixin],
    components:{
      vueDropzone: vue2Dropzone,
      VueEditor,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        last_updated_on: null,
        intervalcalling: null,
        original_doc_desc : null,
        vue_editor_content: null,
        doc: 
        {
          draft_doc_id: null,
          doc_id: null,
          title: null,
          desc: null,
        },
        doc_ori:
        {
          have_draft: 0,
          original_title: null,
          original_desc: null,
        },
        content:null,
        selects:{
          app:[],
          doc:[]
        },
        selectAppOptions:[],
        selectDocOptions:[],

        tagOptions: [],
        tagValues: [],

        dropzoneOptions: {	
          url: apiDomain + 'document/document_attachment_upload',	
          method : 'post',	
          headers: getHeader(),	
          paramName: 'attach_file',	
          params: {	
            doc_id:this.$route.params.id	
          },	
          timeout: 0,
          maxFilesize: 800000,
          maxNumberOfFiles: 1,
          disablePreviews: true,
          addRemoveLinks: true,

          // parallelUploads:2,
          // createImageThumbnails: false	
          // uploadMultiple:true
        },
        tableData: null,
        loginUser : getUserInfo(),
      }
    },
    created()
    {
      this.appTypeList()
      this.docTypeList()
      this.tagsList()

      this.doc.doc_id = this.$route.params.id

      axios.get(apiDomain + 'document/document/' + this.$route.params.id ,{ headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.doc.title = response.data.data[0].doc_title
          this.original_doc_desc = response.data.data[0].doc_desc
          this.vue_editor_content = response.data.data[0].doc_desc
          this.selects.app = response.data.data[0].app_type.app_type_id
          this.selects.doc = response.data.data[0].document_type.doc_type_id

          let valuesOfTags = [];
          valuesOfTags.push(response.data.data[0].tags);

          let arraysOfTagsNames = valuesOfTags[0];

          const tagNames = arraysOfTagsNames.map(({ tag_name }) => tag_name);
          this.tagValues = tagNames;
        }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Show Document")
      });

      this.getAttachmentList()
      this.getDraft()
      this.debouncedHandler = debounce(() => {
        this.updateDraft()
      }, 60000);
    },
    beforeDestroy() {
    this.debouncedHandler.cancel();
    },
    methods: {
      getDraft()
      {
        axios.get(apiDomain + 'document/document_draft.show_by_document_id/' + this.$route.params.id ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.doc.draft_doc_id = response.data.data[0].draft_doc_id
            this.doc_ori.original_title = this.doc.title
            this.doc_ori.original_desc = this.doc.desc
            this.doc_ori.have_draft = 1
            this.doc.title = response.data.data[0].doc_title
            this.vue_editor_content = response.data.data[0].doc_desc
            
            this.$notify({
            message:
              '<b>Document Draft : ' + this.doc.title + '</b> - Previous Document Draft been Loaded from Memory.',
            timeout: 40000,
            icon: 'ni ni-bell-55',
            type: 'default',
            });
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Show Document Draft")
        });
      },
      updateDraft()
      {
        if ( this.doc.draft_doc_id == null )
        {
          const postData = 
          {
            original_doc_id : this.doc.doc_id,
            doc_title : this.doc.title,
            doc_desc  : this.vue_editor_content,
          }

          axios.post(apiDomain + 'document/document_draft' , postData, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.doc.draft_doc_id = response.data.data[0].draft_doc_id
            }
          })
          .catch ( error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Update Document")
          });
        }
        else
        {
          const postData = 
          {
            original_doc_id : this.doc.doc_id,
            doc_title : this.doc.title,
            doc_desc  : this.vue_editor_content,
          }

          axios.put(apiDomain + 'document/document_draft/' + this.doc.draft_doc_id , postData, { headers: getHeader() })
          .catch ( error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Update Document")
          });
        }
        
        this.last_updated_on = getcurrentDateTime()
      },
      updateData()
      {
        const postData = {
          doc_title       : this.doc.title,
          doc_desc        : this.vue_editor_content,
          tagValues  : this.tagValues
        }

        axios.put(apiDomain + 'document/document/' + this.doc.doc_id , postData, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.$notify({
            message:
              '<b>Edit Document : ' + this.doc.title + '</b> - Successfully Updated.',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
            });
            this.doc.draft_doc_id = null
            this.$router.push({path: '/doc/documents'})

          }
        })
        .catch ( error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Update Document")
        });
        
        const str = JSON.stringify(postData);
        console.log(str);
      },
      appTypeList(){
        axios.get(apiDomain + 'setup/app_type', { headers: getHeader() })
        .then(response => {
            this.selectAppOptions = response.data.data
        })
        .catch ( error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Edit Document")
        });
         
      },
      docTypeList(){
        axios.get(apiDomain + 'setup/document_type', { headers: getHeader() })
        .then(response => {
            this.selectDocOptions = response.data.data
        })            
        .catch ( error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Update Document")
        });
      },
      tagsList(){
        axios.get(apiDomain + 'document/document.show_available_tags_for_edit_byDocID', { headers: getHeader() })
        .then(response => {
            // this.selectDocOptions = response.data.data
            let allTags = [];
            allTags.push(response.data.data);
            this.tagOptions = allTags[0];
            console.log('oi', this.tagOptions)
        })            
        .catch ( error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Tags Options")
        });
      },
      uploadSuccess(){
        this.getAttachmentList()
        this.$notify({
        message:
          '<b>Edit Document : ' + this.doc.title + '</b> - Successfully Updated.',
        timeout: 10000,
        icon: 'ni ni-bell-55',
        type: 'default',
        });
      },
      getAttachmentList(){
        axios.get(apiDomain + 'document/document_attachment_upload.show_uploads_by_document/' + this.$route.params.id ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.tableData = response.data.data
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show Document Attachment")
        });
      },
      onDelete(row){
        swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.delete(apiDomain + 'document/document_attachment_upload/' + row.doc_attach_id ,{ headers: getHeader() })
            .then ( response => {
              if (response.status === 200)
              {
                this.$notify({
                  message:
                    '<b>Document Attachment : ' + row.attach_file + '</b> - Successfully Deleted.',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
                this.getAttachmentList()
              }
            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Delete Document Attachment")
            });
          } 
        })

      },
      onDownload(row){
        axios.get(apiDomain + 'document/document_attachment_upload.download_document_attachment/' + row.doc_attach_id ,{ headers: getHeader(), responseType: "blob" })
        .then ( response => {
          if (response.status === 200)
          {
              var FILE = window.URL.createObjectURL(new Blob([response.data]));        
              var docUrl = document.createElement('a');

              docUrl.href = FILE;
              docUrl.setAttribute('download', row.attach_file);
              document.body.appendChild(docUrl);
              docUrl.click();
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Download Document Attachment")
        });
      },
      test(){
        // console.log('options', this.options);
        console.log('value', this.tagValues);
      }
      // clearDropzone(){
      //   this.$refs.myVueDropzone.dropzone.removeAllFiles()
      // }
    }
  }
</script>
