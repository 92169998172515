<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Group</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>

        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="primary" @click="$router.back()">Back</base-button>
          <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">New Group App Setting</h3>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="6">
                    <base-input label="Group Member List">
                      <el-select v-model="selects.multiple" filterable multiple
                                  placeholder="Select Group Member(s)">
                        <el-option v-for="option in selectOptions"
                                    :key="option.name"
                                    :label="option.name"
                                    :value="option.id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>

                <base-button type="primary" @click="createData()">Create</base-button>
                <base-button type="primary" @click="$router.back()">Cancel</base-button>

              </form>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import { Select , Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components:{
      [Select.name]: Select,
      [Option.name]: Option,
    },   
    data() {
      return {
        group: {
          group_name: null,
        },
        selects:{
          multiple:[]
        },
        selectOptions:[]
      }
    },
    created() {
        this.userList()
        this.groupMemberList()
    },
    methods: {
      createData(){
          const postData = {
            group_id : this.$route.params.id,
            user_id  : this.selects.multiple,
          }
          const str = JSON.stringify(postData);
          console.log(str);
          console.table(postData);

          axios.post(apiDomain + 'groups/group_member', postData ,{ headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.$notify({
                message:
                  '<b>Add Group Members for : ' + response.data.data[0].group.group_name + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
              this.$router.back()

            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Add Group Type")
              
          });
      },
      groupMemberList(){
        axios.get(apiDomain + 'groups/group_member.show_by_group/' + this.$route.params.id , { headers: getHeader() })
        .then(response => {
            
            const obj = response.data.data
            let list = [];
            Object.keys(obj).forEach(key =>{
              list.push(obj[key].user.user_id)
            })
            this.selects.multiple = list
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Add Group Type")
            
        });
        const str = JSON.stringify(this.tableData, null, 2);
        console.log(str);
      },
      userList(){
        axios.get(apiDomain + 'setup/user', { headers: getHeader() })
        .then(response => {
          this.selectOptions = response.data.data
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Add Group Type")
            
        });
      }

    }
  }
</script>
