export default [
    {
      id: 1,
      name: 'John Micahael',
      image: '/img/theme/team-1.jpg',
      createdAt: '10/09/2018',
      product: 'Argon Dashboard PRO',
      active: true
    },
    {
      id: 2,
      name: 'Alexandra Smith',
      image: '/img/theme/team-2.jpg',
      createdAt: '08/09/2018',
      product: 'Argon Design System',
      active: false
    },
    {
      id: 3,
      name: 'Samantha Ivy',
      image: '/img/theme/team-3.jpg',
      createdAt: '30/08/2018',
      product: 'Black Dashboard',
      active: false
    },
    {
      id: 4,
      name: 'John Michael',
      image: '/img/theme/team-1.jpg',
      createdAt: '10/09/2018',
      product: 'Argon Dashboard PRO',
      active: true
    },
    {
      id: 5,
      name: 'Alexandra Smith',
      image: '/img/theme/team-2.jpg',
      createdAt: '30/09/2018',
      product: 'Vue Argon Dashboard',
      active: true
    },
  ];
  