<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Document</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">View Document - {{doc.title}}</h3>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="4">
                    <base-input placeholder="Document Title" v-model="doc.title" diabled></base-input>
                  </b-col>
                  
                  <b-col md="4">
                    <base-input >
                      <el-select v-model="selects.app" filterable placeholder="Select Apps" disabled>
                        <el-option Selected disabled value="x">-- Please Select App Type --</el-option>
                        <el-option v-for="option in selectAppOptions" :key="option.app_type_name" :label="option.app_type_name" v-bind:value="option.app_type_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>

                  <b-col md="4">
                    <base-input >
                      <el-select v-model="selects.doc" filterable placeholder="Select Docs" disabled>
                        <el-option Selected disabled value="x">-- Please Select Doc Type --</el-option>
                        <el-option v-for="option in selectDocOptions" :key="option.doc_type_name" :label="option.doc_type_name" v-bind:value="option.doc_type_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12" class="form-group">
                      <b-card title="Document Content">
                        <b-card-text v-html="vue_editor_content"></b-card-text>
                      </b-card>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12" class="form-group">
                    <el-table class="table-responsive align-items-center table-flush"
                              header-row-class-name="thead-light"
                              :data="tableData">
                      <el-table-column label="id"
                                      min-width="50px"
                                      prop="doc_attach_id"
                                      sortable>
                        <template v-slot="{row}">
                          <b>{{row.doc_attach_id}}</b>
                        </template>
                      </el-table-column>

                      <el-table-column label="attach file"
                                      prop="attach_file"
                                      min-width="150px"
                                      sortable>
                        <template v-slot="{row}">
                          <b>{{row.attach_file}}</b>
                        </template>
                      </el-table-column>

                      <el-table-column label="Date Uploaded"
                        prop="created_at"
                        min-width="120px"
                        sortable>
                        <template v-slot="{row}">
                          <b>{{row.created_at}}</b>
                        </template>
                      </el-table-column>

                      <el-table-column label="Created By"
                                        min-width="100px"
                                        prop="created_by.name"
                                        sortable>
                        <template v-slot="{row}">
                          <b>{{row.created_by.name}}</b>
                        </template>
                      </el-table-column>

                      <el-table-column min-width="50px" align="center">
                        <div slot-scope="{row}" class="table-actions">
                          <el-tooltip content="Download" placement="top">
                            <a href="" @click.prevent="onDownload(row)" class="table-action" data-toggle="tooltip" data-original-title="Doc Edit">
                              <i class="fas fa-download"></i>
                            </a>
                          </el-tooltip>
                        </div>
                      </el-table-column>
                    </el-table>
                  </b-col>
                </b-row>

                <base-button type="primary" @click="$router.back()">Back</base-button>
              </form>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import { Select , Option , Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip } from 'element-ui'
  import {apiDomain, getHeader } from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'


  export default {
    mixins: [ErrorFunctionMixin],
    components:{
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        vue_editor_content: null,
        doc: 
        {
          draft_doc_id: null,
          doc_id: null,
          title: null,
          desc: null,
        },
        content:null,
        selects:{
          app:[],
          doc:[]
        },
        selectAppOptions:[],
        selectDocOptions:[],
        tableData: null,

      }
    },
    created()
    {
      this.appTypeList()
      this.docTypeList()

      this.doc.doc_id = this.$route.params.id

      axios.get(apiDomain + 'document/document/' + this.$route.params.id ,{ headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.doc.title = response.data.data[0].doc_title
          this.vue_editor_content = response.data.data[0].doc_desc
          this.selects.app = response.data.data[0].app_type.app_type_id
          this.selects.doc = response.data.data[0].document_type.doc_type_id
        }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Show Document")
      });

      this.getAttachmentList()
    },
    methods: {
      appTypeList(){
        axios.get(apiDomain + 'setup/app_type', { headers: getHeader() })
        .then(response => {
            this.selectAppOptions = response.data.data
        }) 
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show Document")
        });

      },
      docTypeList(){
        axios.get(apiDomain + 'setup/document_type', { headers: getHeader() })
        .then(response => {
            this.selectDocOptions = response.data.data
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show Document")
        });    
      },
      getAttachmentList(){
        axios.get(apiDomain + 'document/document_attachment_upload.show_uploads_by_document/' + this.$route.params.id ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.tableData = response.data.data
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show Document Attachment")
        });
      },
      onDownload(row){
        axios.get(apiDomain + 'document/document_attachment_upload.download_document_attachment/' + row.doc_attach_id ,{ headers: getHeader(), responseType: "blob" })
        .then ( response => {
          if (response.status === 200)
          {
              var FILE = window.URL.createObjectURL(new Blob([response.data]));        
              var docUrl = document.createElement('a');

              docUrl.href = FILE;
              docUrl.setAttribute('download', row.attach_file);
              document.body.appendChild(docUrl);
              docUrl.click();
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Download Document Attachment")
        });
      }
      // clearDropzone(){
      //   this.$refs.myVueDropzone.dropzone.removeAllFiles()
      // }
    }
  }
</script>
